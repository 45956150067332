<template>
    <div>
        <svg @click="$emit('pause')" :class="[timer_alert ? 'text-red-600' : '']" class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span :class="[timer_alert ? 'text-red-600' : '']" class="text-sm flex items-center">
            <span class="text-xs" v-if="if_paused_recently"><loader class="w-4 text-electric-blue"></loader></span>
            <span role="timer" v-else>{{ time_remaining }}</span>
            <span :class="[timer_alert ? 'text-red-500' : 'text-gray-500']" class="font-medium text-sm ml-1 total-time">/ {{ time }}</span>
        </span>
        <span class="opacity-0 w-0" role="timer" aria-live="assertive">{{ time_remaining_announcement }}</span>
    </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
export default {
    emits: ['pause'],
    components: {
        Loader
    },
    props: {
        timer_alert: {
            required: true,
        },
        if_paused_recently: {
            required: true,
        },
        time_remaining: {
            required: true,
        },
        time: {
            required: true,
        },
        time_remaining_announcement: {
            required: true
        }
    }
}
</script>
