import Echo from 'laravel-echo';

export default {
    install: (app) => {

        app.config.globalProperties.$registerWSSConnection = (successCallback, errorCallback, disconnect) => {
            disconnect = typeof disconnect === 'undefined' ? true : disconnect;
            successCallback = successCallback || function(){};
            errorCallback = errorCallback || function(){};
            app.config.globalProperties.$echo = new Echo({
                broadcaster:        'pusher',
                key:                import.meta.env.VITE_PUSHER_APP_KEY,
                wsHost:             import.meta.env.VITE_PUSHER_APP_HOST,
                wsPort:             import.meta.env.VITE_PUSHER_APP_PORT,
                wssPort:            import.meta.env.VITE_PUSHER_APP_PORT,
                disableStats:       true,
                encrypted:          false,
                forceTLS:           false,
                enabledTransports:  ['ws', 'wss'],
                authorizer:         (channel) => {
                    return {
                        authorize: (socketId, callback) => {
                            app.config.globalProperties.$axios.post(endpoint('/api/candidates/broadcasting/auth'), {
                                socket_id: socketId,
                                channel_name: channel.name,
                                identifier: 'candidate'
                            })
                                .then(response => {
                                    callback(false, response.data);
                                })
                                .catch(error => {
                                    callback(true, error);
                                });
                        }
                    };
                },
            });

            app.config.globalProperties.$echo.connector.pusher.connection.bind('connected', () => {
                successCallback(app);
            });

            app.config.globalProperties.$echo.connector.pusher.connection.bind('unavailable', () => {
                errorCallback(app);
                if(disconnect) {
                    app.config.globalProperties.$echo.disconnect();
                }
            });
        };

        app.config.globalProperties.$registerWSSBindings = (inst) => {

            inst.$store.commit('setState', 'initialising');
            inst.$echo.connector.pusher.connection.bind('connecting', (payload) => {

                /**
                 * All dependencies have been loaded and Channels is trying to connect.
                 * The connection will also enter this state when it is trying to reconnect after a connection failure.
                 */
                inst.$store.commit('setState', 'connecting');

            });

            inst.$echo.connector.pusher.connection.bind('connected', (payload) => {

                /**
                 * The connection to Channels is open and authenticated with your app.
                 */
                if(['offline', 'unavailable', 'failed', 'disconnected'].indexOf(inst.$store.state.state) !== -1) {
                    inst.$global.success(inst.$store.state.translatables.translation.notifications.messages.reconnected);
                }

                inst.$store.commit('setState', 'connected');

            });

            inst.$echo.connector.pusher.connection.bind('unavailable', (payload) => {

                /**
                 * 	The connection is temporarily unavailable. In most cases this means that there is no internet connection.
                 * 	It could also mean that Channels is down, or some intermediary is blocking the connection. In this state,
                 * 	pusher-js will automatically retry the connection every 15 seconds.
                 */
                inst.$store.commit('setState', 'unavailable');

            });

            inst.$echo.connector.pusher.connection.bind('failed', (payload) => {

                /**
                 * Channels is not supported by the browser.
                 * This implies that WebSockets are not natively available and an HTTP-based transport could not be found.
                 */
                inst.$store.commit('setState', 'failed');

            });

            inst.$echo.connector.pusher.connection.bind('disconnected', (payload) => {

                /**
                 * The Channels connection was previously connected and has now intentionally been closed
                 */
                inst.$store.commit('setState', 'disconnected');

            });

            window.addEventListener('offline', () => {

                inst.$store.commit('setState', 'offline');

            });

            window.addEventListener('online', () => {

                inst.$store.commit('setState', 'connected');
                inst.$global.success(inst.$store.state.translatables.translation.notifications.messages.reconnected);

            });

        };

        if(app.config.globalProperties.$store.state.user) {
            window.bus.$gates.registerAuthBootFunc(() => {
                app.config.globalProperties.$registerWSSConnection(() => {
                    app.config.globalProperties.$registerWSSBindings(app.config.globalProperties);
                }, () => {
                    app.config.globalProperties.$store.commit('setState', 'unavailable');
                },
                    false);
            });
        }

    }
}

