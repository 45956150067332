<template>
    <div>
        <layout>
            <template v-slot:contents>
                <div v-if="error" class="rounded-md bg-red-50 p-4">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"/>
                            </svg>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-sm leading-5 font-medium text-red-800">
                                Whoops!
                            </h3>
                            <div class="mt-2 text-sm leading-5 text-red-700">
                                <ul class="list-disc pl-5">
                                    <li class="mt-1">
                                        {{ error }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="display_class" v-else-if="logged_in" class="rounded-md bg-white p-4">
                    <div class="section pad-sml space-y-4">
                        <div class="flex items-center">
                            <h1 class="text-2xl">{{ title }}</h1>
                            <div class="ml-auto">
                                <div class="flex mb-2 print:hidden">
                                    <button @click.prevent="print()" class="ml-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                                        </svg>
                                    </button>
                                </div>
                                <div>
                                    <span class="uppercase">Overall score:</span> <span>{{ score }}</span>/<span>{{ max_score }}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="hidden sm:block print:hidden">
                                <div class="border-b border-gray-200">
                                    <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                                        <a @click.prevent="changeDisplay('performance')" href="#" :class="[display_class === 'show-performance-content' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300']" class="group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm">
                                            <svg :class="[display_class === 'show-performance-content' ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500']" class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                                            </svg>
                                            <span>Performance</span>
                                        </a>
                                        <a @click.prevent="changeDisplay('sessions-summary-tag')" href="#" :class="[display_class === 'show-sessions-summary-tag-content' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300']" class="group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm">
                                            <svg :class="[display_class === 'show-sessions-summary-tag-content' ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500']" class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
                                            </svg>
                                            <span>Skills</span>
                                        </a>
                                        <a @click.prevent="changeDisplay('session-detail')" href="#" :class="[display_class === 'show-session-detail-content' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300']" class="group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm">
                                            <svg :class="[display_class === 'show-session-detail-content' ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500']" class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                                            </svg>
                                            <span>Session details</span>
                                        </a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div id="performance-content" class="report-tab-content">
                            <div v-if="traits.length" class="mt-8 flex flex-col">
                                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <div class="overflow-hidden ring-1 ring-gray-300">
                                            <table class="min-w-full divide-y divide-gray-300">
                                                <thead class="bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Question No.</th>
                                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Score</th>
                                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Performance</th>
                                                </tr>
                                                </thead>
                                                <tbody class="divide-y divide-gray-300 bg-white">
                                                <tr v-for="trait in traits" :key="trait.tag">
                                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ trait.tag }}</td>
                                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ trait.score !== null ? trait.score : '?' }}/{{ trait.max_score !== null ? trait.max_score : '?' }}</td>
                                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-400 overflow-hidden">
                                                            <div :class="percentageClass(trait)" class="h-2.5 rounded-full" :style="'width: ' + (((trait.score/trait.max_score)*100)+2) + '%;'"></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="session-detail-content" class="report-tab-content">
                            <div id="session-detail"></div>
                        </div>
                        <div id="sessions-summary-tag-content" class="report-tab-content">
                            <div id="sessions-summary-tag"></div>
                        </div>
                    </div>
                </div>
            </template>
        </layout>
    </div>
</template>

<script>
import Layout from "@/components/Layout.vue";

export default {
    components: {
        Layout
    },
    beforeRouteEnter(to, from, next) {
        window.bus.$gates.BeforeD2CReport(to, from, next);
    },
    data() {
        return {
            display_class: 'show-performance-content',
            title: '',
            score: '',
            max_score: '',
            error: null,
            logged_in: false,
            request: null,
            script_url: null,
            learnosityInst: null,
            traits: [],
            scores: {}
        }
    },
    methods: {
        changeDisplay(display) {
            this.display_class = `show-${display}-content`;
        },
        loadLearnosity() {
            let script = document.createElement('script');
            script.src = this.script_url;
            script.onload = () => {
                let response = JSON.parse(this.request);
                response.request.reports[0].user_id = String(response.request.reports[0].user_id);
                this.learnosityInst = LearnosityReports.init(response);
            };
            document.head.appendChild(script);
        },
        percentageClass(trait) {
            let percentage = (trait.score/trait.max_score)*100;

            if(trait.score === null || trait.max_score === null) return 'bg-gray-400';
            if(percentage < 10) return 'bg-red-500';
            if(percentage < 50) return 'bg-yellow-500';
            if(percentage < 70) return 'bg-green-200';
            return 'bg-green-600';
        },
        print() {
            window.print();
        }
    }
}
</script>

<style>
.report-tab-content {
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

.show-performance-content #performance-content,
.show-sessions-summary-tag-content #sessions-summary-tag-content,
.show-session-detail-content #session-detail-content {
    height: auto;
    visibility: visible;
}
</style>
