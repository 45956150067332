<template>
    <div>
        <layout>
            <template v-slot:title>
                <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 flex">
                    <h1 class="text-3xl leading-9 font-bold text-white">
                        {{ translation.headings.review }}: {{ assessment.title }}
                    </h1>
                </div>
            </template>
            <template v-slot:contents>
                <div class="relative">
                    <div class="h-64 relative" id="learnosity_assess">
                        <div class="absolute inset-0 flex items-center justify-center">
                            <div class="text-center">
                                <span class="text-lg">{{ translation.messages.loading_assessment_player }}</span>
                                <loader class="w-6 h-6 text-blue-500 mx-auto mt-2"></loader>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </layout>
    </div>
</template>


<script>
    import Layout from "@/components/Layout.vue";
    import Loader from "@/components/Loader.vue";
    import {learnosityAccessibilityPalettes, learnosityEventsForSpellCheck} from "@/utils/learnosity";

    export default {
        components: {
            Layout,
            Loader
        },
        beforeRouteEnter (to, from, next) {
            window.bus.$gates.BeforeEnterReviewAssessment(to, from, next);
        },
        mounted() {
            this.$global.disconnectFromChannels();
            this.loadLearnosity();
        },
        data() {
            return {
                api: null
            }
        },
        computed: {
            assessment() {
                return this.$store.state.assessment.version.assessment;
            },
            session() {
                return this.$store.state.assessment;
            },
            translation() {
                return this.$store.state.translatables.translation;
            },
        },
        methods: {
            loadLearnosity() {
                this.api = window.LearnosityItems.init(this.session.learnosity_data.options, {
                    readyListener: () => {
                        learnosityEventsForSpellCheck(this)
                        if(this.session.with_reader){
                            window.rsCallbacks;
                            ReadSpeaker.q(function(){
                                ReadSpeaker.Learnosity.init();
                            });
                        }

                        learnosityAccessibilityPalettes(this)
                    },
                });
            },
        }
    }
</script>
