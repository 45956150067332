
<template>
    <div>
        <layout>
            <template v-slot:title>
                <div v-if="assessment" class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 flex">
                    <h1 class="text-3xl leading-9 font-bold text-white">
                        {{ translation.headings.waiting_room }} - {{ assessment.title }}
                    </h1>
                </div>
            </template>
            <template v-slot:contents>
                <div v-if="assessment" class="border border-blue-100 py-10 rounded-md bg-blue-50 min-h-body flex items-center justify-center">
                    <div class="text-center ">
                        <svg class="w-10 h-10 mb-4 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <p>{{ translation.headings.assessment_starts }} {{ count_down }}</p>
                        <p>{{ translation.messages.assessment_starts }}</p>
                        <span class="inline-flex rounded-md shadow-sm mt-4">
                            <button :test-ref="'leave-waiting-room-' + session.id" @click.prevent="leaveWaitingRoom" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                </svg>
                                {{ translation.buttons_links.leave_waiting_room }}
                            </button>
                        </span>
                    </div>
                </div>
            </template>
        </layout>
    </div>
</template>

<script>
    import Layout from "@/components/Layout.vue";
    import Loader from "@/components/Loader.vue";

    export default {
        components: {
            Layout,
            Loader
        },
        beforeRouteEnter (to, from, next) {
            window.bus.$gates.BeforeEnterWaitingRoom(to, from, next);
        },
        mounted() {
            this.setCountDownTicker();
            this.registerSocketChannels(() => {
                this.listenToSocketChannels();
            });
        },
        data() {
            return {
                seconds_lapsed: 0,
                count_down: ''
            }
        },
        computed: {
            translation() {
                return this.$store.state.translatables.translation;
            },
            session() {
                return this.$store.state.assessment;
            },
            assessment() {
                return this.$store.state.assessment ? this.$store.state.assessment.version.assessment : null;
            },
            schedule() {
                return this.session ? this.session.group.assessment_schedule : null;
            },
            candidate() {
                return this.$store.state.user;
            }
        },
        methods: {
            registerSocketChannels(subscribed) {
                this.$global.registerCandidateChannel();
                this.$global.registerChannel('public-group', `App.AssessmentScheduleGroup.${this.session.group.id}`, 'presence', subscribed);
            },
            setStatus(status) {
                this.$store.commit('setStatus', status);
                this.inform("candidate:focus", {status});
            },
            listenToSocketChannels() {
                this.$global.channel('public-group', (socket) => {
                    socket.listen('.invigilator:assessment-start', message => {
                        if(message.group.id === this.session.group.id) {
                            this.$router.push({
                                name: 'assessment',
                                params: {
                                    id: this.session.id
                                }
                            });
                        }
                    });
                });
            },
            leaveWaitingRoom() {
                this.$store.commit('setAssessment', null);
                this.$router.push({
                    name: 'home'
                });
            },
            setCountDownTicker() {
                this.$global.ticker('waiting-room:seconds-lapsed', (_seconds, remove) => {
                    if(! this.$store.state.assessment) return;
                    this.$global.timeUntil(this.session.group.assessment_schedule.window_starts_at, time => {
                        if(time.completed) {
                            this.count_down = 'anytime now';
                            remove();
                        } else if(time.days) {
                            this.count_down = 'in ' + time.days + " days,  " + time.hours + " hours, " + time.minutes + " minutes";
                        } else if(time.hours) {
                            this.count_down = 'in ' + time.hours + " hours, " + time.minutes + " minutes";
                        } else if(time.minutes) {
                            this.count_down = 'in ' + time.minutes + " minutes";
                        } else if(time.seconds) {
                            this.count_down = 'in ' + time.seconds + " seconds";
                        }
                    });
                });
            },
            inform(event, payload) {
                payload.session_id = this.session.id;
                this.$global.channel('public-group', (socket) => {
                    socket.whisper(event, payload);
                });
            }
        }
    }
</script>
