<template>
    <div>
        <div class="bg-gray-800 pb-32">
            <nav class="bg-gray-800">
                <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div class="border-b border-gray-700">
                        <div :class="rtl('flex-row-reverse')" class="flex items-center justify-between h-16 px-4 sm:px-0 text-white">
                            <div :class="rtl('flex-row-reverse')" class="flex items-center">
                                <div :class="rtl('ml-4')" class="flex-shrink-0">
                                    <img aria-label="Pearson Logo" class="h-8 bg-gray-800" src="/images/logo.svg" alt="Pearson logo">
                                </div>
                                <div v-if="user && !iframe && ['submit_failed', 'submitted'].includes(status)" class="hidden md:block">
                                    <div role="navigation" :class="rtl('flex-row-reverse mr-10 space-x-reverse') + ltr('ml-10')" class="flex space-x-4 items-baseline">
                                        <router-link role="link" aria-label="View active assessments" id="assessments" class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300" exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300" to="/">{{ translation.headings.assessments }}</router-link>
                                        <router-link v-if="!sandbox" role="link" aria-label="View upcoming assessments" id="upcoming-assessments" class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300" exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300" to="/upcoming-assessments">{{ translation.headings.upcoming }}</router-link>
                                        <router-link v-if="!sandbox" role="link" aria-label="View completed assessments" id="completed-assessments" class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300" exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300" to="/completed-assessments">{{ translation.headings.completed }}</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="relative z-20">
                                <div :class="rtl('flex-row-reverse mr-4 md:mr-6') + ltr('ml-4 md:ml-6')" class="items-center hidden md:flex">
                                    <div aria-label="Your full name" v-if="user" class="text-white mr-4 text-sm">
                                        {{ user.full_name }}
                                    </div>
                                    <span v-if="! session.ended_at && showHelpButton" :class="rtl('mr-4') + ltr('ml-auto')" class="inline-flex rounded-md shadow-sm">
                                        <button :disabled="loading" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 focus:ring-2 focus:ring-indigo-300" @click="help ? cancelHelp() : askForHelp()">
                                            <svg v-if="loading"  aria-hidden="true" class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                            </svg>
                                            <svg v-else-if="help" class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01" />
                                            </svg>
                                            <svg v-else class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                            </svg>
                                            <span v-if="loading">{{ translation.buttons_links.loading }}</span>
                                            <span v-else-if="help">{{ translation.buttons_links.please_wait }}&mldr;</span>
                                            <span v-else>{{ translation.buttons_links.help }}</span>
                                        </button>
                                    </span>
                                    <language-switcher v-if="['submit_failed', 'submitted'].includes(status)"></language-switcher>
                                    <profile-menu v-if="!iframe && ['submit_failed', 'submitted'].includes(status)" @logout="logout" :open="profile_open"></profile-menu>
                                </div>
                            </div>
                            <div v-if="user" class="-mr-2 flex md:hidden">
                                <button @click.prevent="mobile_menu_open = !mobile_menu_open" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white focus:ring-2 focus:ring-indigo-300">
                                    <svg aria-hidden="true" v-if="! mobile_menu_open" class="block h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                                    </svg>
                                    <svg aria-hidden="true" v-if="mobile_menu_open" class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                                <language-switcher v-if="status === 'submitted'" :class="rtl('ml-3') + ltr('mr-3')"></language-switcher>
                            </div>
                        </div>
                    </div>
                </div>
                <mobile-menu @logout="logout" :iframe="iframe" :open="mobile_menu_open"></mobile-menu>
            </nav>
            <header :class="[status === 'active' ? '' : 'py-10']" role="group" aria-label="Heading">
                <slot name="title"></slot>
                <div v-if="! hasTitleOverride()" class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h1 v-if="title" :class="rtl('text-right')" class="text-3xl leading-9 font-bold text-white">
                        {{ title }}
                    </h1>
                </div>
            </header>
        </div>
        <main class="-mt-32">
            <slot name="contents"></slot>
        </main>
        <footer class="bg-white border-t border-gray-100 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="max-w-screen-xl mx-auto py-6 px-4 overflow-hidden space-y-8 sm:px-6 lg:px-8">
                <p class="mt-8 text-center text-xs leading-6 text-gray-500">© {{ date }}
                    <a v-if="proctored" class="underline" href="#" @click.prevent="triggerPause">Pearson</a>
                    <span v-else class="underline">Pearson</span>
                    · {{ version }}
                </p>
            </div>
        </footer>
        <network-connection></network-connection>
        <blobs-modal :is-open="show_blobs_modal" @close="show_blobs_modal = false"></blobs-modal>
    </div>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import NetworkConnection from "@/components/NetworkConnection.vue";
import MobileMenu from "@/components/MobileMenu.vue";
import ProfileMenu from "@/components/ProfileMenu.vue";
import BlobsModal from "@/components/BlobsModal.vue";
import ComputedBus from "@/utils/computed-bus.js";
import MethodsBus from "@/utils/methods-bus.js";

export default {
    emits: ['asked-for-help','cancel-help'],
    props: {
        proctored: {
            default: false,
            type: Boolean
        },
        title: {
            type: String,
            default: ''
        },
        iframe: {
            type: Boolean,
            default: false
        },
        status: {
            type: String,
            default: 'active'
        },
        session: {
            type: Object
        },
        help: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    components: {
        NetworkConnection,
        LanguageSwitcher,
        MobileMenu,
        ProfileMenu,
        BlobsModal
    },
    data() {
        return {
            profile_open: false,
            mobile_menu_open: false,
            show_blobs_modal: false
        };
    },
    computed: {
        ...ComputedBus,
        showHelpButton() {
            return this.$store.state.player_config.show_help_button;
        },
        assessmentPage() {
            return this.$route.name === 'assessment';
        },
        date() {
            return new Date().getFullYear();
        },
        version() {
            return this.$store.state.translatables.version;
        },
        translation() {
            return this.$store.state.translatables.translation;
        },
        user() {
            return this.$store.state.user;
        },
        sandbox() {
            return window.location.pathname.includes("sandbox");
        },
    },
    methods: {
        ...MethodsBus,
        triggerPause() {
            this.$store.commit('triggerEvent', {
                name: 'pause',
                data: {}
            })
        },
        askForHelp() {
            this.$emit('asked-for-help');
        },
        cancelHelp(){
           this.$emit('cancel-help');
        },
        hasTitleOverride() {
            return !! this.$slots.title;
        },
        logout() {
            this.$global.pageIsLoading();
            this.profile_open = false;
            this.setXSRFToken(() => {
                this.$axios.post(endpoint('/sanctum/candidates/revoke'), {}).then(() => {
                    this.$global.disconnectFromChannels(true);
                    this.$store.commit('clear', this);
                    this.$router.push({
                        name: 'login'
                    });
                })
                .catch(() => {
                    this.$store.commit('clear', this);
                    this.$router.push({
                        name: 'login'
                    });
                });
            });
        },
        route(route) {
            this.$router.push(route);
        },
    }
}
</script>
