import Global from "./global";

export default {

    data: {
        app: null,
        booted: [],
        authBooted: [],
        isSandbox: window.location.pathname.includes("sandbox")
    },

    /**
     * Booted
     * @param inst
     */
    booted(inst) {
        this.data.booted.forEach(func => {
            func(inst);
        });

        this.data.booted = [];
    },

    authBooted(inst, callback) {
        callback = callback || function(){};
        this.data.authBooted.forEach(func => {
            func(inst);
        });

        this.data.authBooted = [];
        callback();
    },

    /**
     * Register Boot Func
     * @param func
     */
    registerBootFunc(func) {
        this.data.booted.push(func);
    },

    /**
     * Register Boot Func
     * @param func
     */
    registerAuthBootFunc(func) {
        this.data.authBooted.push(func);
    },

    /**
     * App
     * @param app
     */
    app(app) {
        return this.data.app = app;
    },

    /**
     * Before Enter Proctored Assessment
     * @param to
     * @param from
     * @param next
     * @param callback
     * @constructor
     */
    BeforeEnterProctoredAssessment(to, from, next, callback) {
        this.data.app.$global.pageIsLoading();
        this.data.app.$axios.get(endpoint(`/sanctum/proctored-assessment/${to.params.token}?locale=${this.data.app.$store.state.translatables.locale}`))
            .then(response => {
                this.data.app.$store.commit('setUser', response.data.user);
                this.data.app.$store.commit('setAssessment', response.data.assessment);
                this.data.app.$store.commit('setToken', response.data.token);
                this.data.app.$store.commit('setLoggedInAtTime', new Date().toUTCString());
                this.data.app.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.data.app.$store.state.token;
                this.data.app.$registerWSSConnection(() => {
                    this.data.app.$registerWSSBindings(this.data.app);
                    this.authBooted(this.data.app);
                    if (!this.data.app.$global.canAccessAssessment()) {
                        this.data.app.$global.addErrorNotification({
                            header: 'No Access',
                            message: this.data.app.$store.state.translatables.translation.notifications.messages.safe_exam
                        });
                        this.redirect(to, from, next);
                    } else if (this.data.app.$store.state.user === null) {
                        this.redirectToLogin(to, from, next);
                    } else if (new Date(this.data.app.$store.state.assessment.group.assessment_schedule.window_starts_at) > new Date()) {
                        this.data.app.$global.addErrorNotification({message: this.data.app.$store.state.translatables.translation.notifications.messages.assessment_not_started});
                        this.data.app.$store.commit('setAssessment', null);
                        this.redirect(to, from, next);
                    } else {
                        this.data.app.$global.loadLearnosity(() => {
                            next(() => this.data.app.$global.pageIsNotLoading());
                        }, null, to, from);
                    }
                }, () => {
                    this.$global.error('Could not connect to websocket server.');
                });
            })
            .catch(() => {
                this.data.app.$global.addErrorNotification({ message: this.data.app.$store.state.translatables.translation.notifications.messages.error_retrieving_assessment });
                next(inst => {
                    inst.$router.push({
                        name: 'home'
                    })
                });
            });
    },

    /**
     * Before entering assessment view
     * @param to
     * @param from
     * @param next
     * @constructor
     */
    BeforeEnterAssessment(to, from, next) {
        if (this.data.isSandbox) {
            // this.data.app.$store.commit('setAssessment', this.data.app.$store.state.assessment);
            this.data.app.$global.loadLearnosity(() => {
                next(() => this.data.app.$global.pageIsNotLoading());
            }, null, to, from);
            return;
        }
        this.data.app.$global.pageIsLoading();
        if(this.data.app.$store.state.user === null) {
            this.redirectToLogin(to, from, next);
        } else {
            this.data.app.$axios.get(endpoint(`/api/candidates/active-session/${to.params.id}?locale=${this.data.app.$store.state.translatables.locale}`))
                .then(response => {
                    this.data.app.$store.commit('setAssessment', response.data);
                    if (!this.data.app.$global.canAccessAssessment()) {
                        this.data.app.$global.addErrorNotification({
                            header: 'No Access',
                            message: this.data.app.$store.state.translatables.translation.notifications.messages.safe_exam
                        });
                        this.redirect(to, from, next);
                    } else if (this.data.app.$store.state.user === null) {
                        this.redirectToLogin(to, from, next);
                    } else if (new Date(this.data.app.$store.state.assessment.group.assessment_schedule.window_starts_at) > new Date()) {
                        this.data.app.$global.addErrorNotification({message: this.data.app.$store.state.translatables.translation.notifications.messages.assessment_not_started});
                        this.data.app.$store.commit('setAssessment', null);
                        this.redirect(to, from, next);
                    } else {
                        this.data.app.$global.loadLearnosity(() => {
                            next(() => this.data.app.$global.pageIsNotLoading());
                        }, null, to, from);
                    }
                })
                .catch(() => {
                    this.data.app.$global.addErrorNotification({ message: this.data.app.$store.state.translatables.translation.notifications.messages.error_retrieving_assessment });
                    next(inst => {
                        inst.$router.push({
                            name: 'home'
                        })
                    });
                });
        }
    },

    /**
     * Before entering review assessment view
     * @param to
     * @param from
     * @param next
     * @constructor
     */
    BeforeEnterReviewAssessment(to, from, next) {
        this.data.app.$global.pageIsLoading();
        if(this.data.app.$store.state.user === null) {
            this.redirectToLogin(to, from, next);
        } else {
            this.data.app.$axios.get(endpoint(`/api/candidates/completed-assessments?locale=${this.data.app.$store.state.translatables.locale}`))
                .then(response => {
                    this.data.app.$store.commit('setCompletedAssessments', response.data);
                    let updated = this.data.app.$store.state.assessments.completed.filter(assessment => assessment.id === this.data.app.$store.state.assessment.id);
                    if (!updated.length) {
                        this.data.app.$global.addErrorNotification({ message: this.data.app.$store.state.translatables.translation.notifications.messages.no_assessment });
                        this.redirect(to, from, next);
                    } else {
                        this.data.app.$store.commit('setAssessment', updated[0]);
                        this.data.app.$global.loadLearnosity(() => {
                            next(() => this.data.app.$global.pageIsNotLoading());
                        }, null, to, from);
                    }
                })
                .catch(() => {
                    this.data.app.$global.addErrorNotification({ message: this.data.app.$store.state.translatables.translation.notifications.messages.error_retrieving_assessment });
                    this.redirect(to, from, next, '/completed-assessments');
                });
        }
    },

    /**
     * Before entering completed assessments view
     * @param to
     * @param from
     * @param next
     * @constructor
     */
    BeforeEnterCompletedAssessments(to, from, next) {
        this.data.app.$global.pageIsLoading();
        if(this.data.app.$store.state.user === null) {
            this.redirectToLogin(to, from, next);
        } else {
            this.data.app.$axios.get(endpoint(`/api/candidates/completed-assessments?locale=${this.data.app.$store.state.translatables.locale}`))
                .then(response => {
                    this.data.app.$store.commit('setCompletedAssessments', response.data);
                    next(() => this.data.app.$global.pageIsNotLoading());
                })
                .catch(() => {
                    this.data.app.$global.addErrorNotification({ message: this.data.app.$store.state.translatables.translation.notifications.messages.error_retrieving_assessment });
                    this.redirect(to, from, next);
                });
        }
    },

    /**
     * Before entering forgotten password view
     * @param to
     * @param from
     * @param next
     * @constructor
     */
    BeforeEnterForgottenPassword(to, from, next) {
        next(() => this.data.app.$global.pageIsNotLoading());
    },

    /**
     * Before entering home view
     * @param to
     * @param from
     * @param next
     * @constructor
     */
    BeforeEnterHome(to, from, next) {
        this.data.app.$global.pageIsLoading();
        if(this.data.app.$store.state.user === null) {
            this.redirectToLogin(to, from, next);
        } else {
            this.data.app.$axios.get(endpoint(`/api/candidates/active-assessments?locale=${this.data.app.$store.state.translatables.locale}`))
            .then(response => {
                this.data.app.$store.commit('setActiveAssessments', response.data);
                next(() => this.data.app.$global.pageIsNotLoading());
            })
            .catch(() => {
                this.data.app.$global.addErrorNotification({ message: this.data.app.$store.state.translatables.translation.notifications.messages.error_retrieving_assessment });
                next(() => this.data.app.$global.pageIsNotLoading());
            });
        }
    },
    /**
         * Before entering sandbox home view
         * @param to
         * @param from
         * @param next
         * @constructor
         */
    BeforeEnterSandboxHome(to, from, next) {
        this.data.app.$global.pageIsLoading();
        if (this.data.app.$store.state.user === null) {
            this.redirectToLogin(to, from, next);
        } else {
            this.data.app.$axios.get(endpoint(`/api/candidates/assessments/sandbox?locale=${this.data.app.$store.state.translatables.locale}`))
                .then(response => {
                    const mapped = response.data.map(section => {
                        section.assessments = section.versions.map((version) => {
                            const h = Math.floor(version.learnosity_data.options.request.config.time.max_time / 60);
                            const i = version.learnosity_data.options.request.config.time.max_time - h * 60;
                            const s = 0;
                            const endDate = new Date();
                            endDate.setHours(endDate.getHours()+3);
                            return {
                                id: version.learnosity_data.options.request.session_id,
                                "candidate_id": version.learnosity_data.options.request.user_id,
                                // "assessment_schedule_group_id": 383,
                                "assessment_version_id": version.id,
                                "extra_time": 0,
                                "created_at": null,
                                "updated_at": null,
                                "purchase_order_number": "",
                                "proctor_external_session_reference": null,
                                "proctor_token": null,
                                "unique_request_token": null,
                                "deleted_at": null,
                                "started_at": null,
                                "candidate_started_at": null,
                                "submitted_at": null,
                                "ends_at": null,
                                "paused_at": null,
                                "current_question": 0,
                                "max_time": { h, i, s },
                                "absent": 0,
                                learnosity_data: version.learnosity_data,
                                player_config: version.player_config,
                                "connection_status": "offline",
                                version: version,
                                candidate: {
                                    id: version.learnosity_data.options.request.user_id,
                                },
                                group: {
                                    assessment_schedule: {
                                        is_practise: version.learnosity_data.options.request.type === "local_practice",
                                        window_starts_at: new Date().toISOString(),
                                        window_ends_at: endDate.toISOString(),
                                        max_time: version.learnosity_data.options.request.config.time.max_time,
                                    }
                                }
                            }
                        })
                        return section;
                    })
                    this.data.app.$store.commit('setSandboxSections', mapped.filter(section => section.assessments.length > 0));
                    next(() => this.data.app.$global.pageIsNotLoading());
                })
                .catch(() => {
                    this.data.app.$global.addErrorNotification({ message: this.data.app.$store.state.translatables.translation.notifications.messages.error_retrieving_assessment });
                    next(() => this.data.app.$global.pageIsNotLoading());
                    //this.redirect(to, from, next);
                });
        }
    },

    BeforeUsingRealUserRoutes(to, from, next) {
        if (import.meta.env.VITE_SAFE_BROWSER_PARAMETER
            && to.query[import.meta.env.VITE_SAFE_BROWSER_PARAMETER] !== undefined
        ) {
            // Assume this is a "safe" browser.
            this.data.app.$store.commit('setAssumeSafeBrowser', true);
        }

        if (this.data.app.$store.state.user && this.data.app.$store.state.user.is_sandbox) {
            if(!to.path.includes('sandbox')){
                window.location.href = '/sandbox'
                return;
            }
        }

        next();
    },

    /**
     * Before entering home view
     * @param to
     * @param from
     * @param next
     * @constructor
     */
    BeforeEnterLogin(to, from, next) {
        this.data.app.$global.pageIsLoading();
        if(this.data.app.$store.state.user !== null) {
            next({name: 'home'});
        } else {
            next(() => this.data.app.$global.pageIsNotLoading());
        }
    },

    BeforeD2CAuth(to, from, next) {
        this.data.app.$global.pageIsLoading();
        if(this.data.app.$store.state.user !== null) {
            this.data.app.$store.commit('clear');
        }

        this.data.app.$axios.post(endpoint('/sanctum/d2c/token'), {
            a: to.params.client_id,
            b: to.params.assessment_schedule_group_id,
            c: to.params.candidate_id,
            d: to.params.assessment_version_id,
            e: to.params.hash,
        }).then(response => {
            next(inst => {
                inst.$store.commit('setUser', response.data.user);
                inst.$store.commit('setToken', response.data.token);
                inst.$store.commit('setLoggedInAtTime', new Date().toUTCString());
                inst.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + inst.$store.state.token;
                inst.$registerWSSConnection(() => {
                    inst.$registerWSSBindings(inst);
                    inst.$router.push(response.data.route);
                }, () => {
                    inst.error = 'Could not connect to websocket server.';
                    inst.$global.pageIsNotLoading();
                });
            });
        })
        .catch(() => {
            next(inst => {
                inst.error = 'There was an issue logging you in. Please contact support.';
                inst.$global.pageIsNotLoading();
            });
        });
    },

    URLLogin(to, next, success, failed) {
        this.data.app.$global.pageIsLoading();
        if(this.data.app.$store.state.user !== null) {
            this.data.app.$store.commit('clear');
        }

        this.data.app.$axios.post(endpoint('/sanctum/d2c/token'), {
            a: to.params.client_id,
            b: to.params.assessment_schedule_group_id,
            c: to.params.candidate_id,
            d: to.params.assessment_version_id,
            e: to.params.hash,
        }).then(response => {
            next(inst => {
                inst.$store.commit('setUser', response.data.user);
                inst.$store.commit('setToken', response.data.token);
                inst.$store.commit('setLoggedInAtTime', new Date().toUTCString());
                inst.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + inst.$store.state.token;
                inst.$registerWSSConnection(() => {
                    success(inst);
                }, () => {
                    failed(inst)
                });
            });
        })
            .catch(() => {
                failed(inst);
            });
    },

    /**
     * Before D2C Report
     * @param to
     * @param from
     * @param next
     * @constructor
     */
    BeforeD2CReport(to, from, next) {
        this.URLLogin(to, next, (inst) => {
            inst.$registerWSSBindings(inst);
            inst.$axios.get(endpoint(`/api/candidates/report/${to.params.session_id}`))
                .then(response => {
                    inst.request = response.data.request;
                    inst.script_url = response.data.script_url;
                    inst.scores = response.data.scores[0];
                    inst.title = response.data.title;
                    inst.score = response.data.score;
                    inst.max_score = response.data.max_score;
                    inst.traits = response.data.traits;
                    inst.logged_in = true;
                    inst.loadLearnosity();
                    inst.$global.pageIsNotLoading();
                }).catch(() => {
                    inst.error = 'Could not retrieve session.';
                    inst.$global.pageIsNotLoading();
            });
        }, (inst) => {
            inst.error = 'There was an issue logging you in. Please contact support.';
            inst.$global.pageIsNotLoading();
        });
    },

    /**
     * Before entering register view
     * @param to
     * @param from
     * @param next
     * @constructor
     */
    BeforeEnterRegister(to, from, next) {
        this.data.app.$global.pageIsLoading();
        if(this.data.app.$store.state.user !== null) {
            this.redirect(to, from, next);
        } else {
            next(() => this.data.app.$global.pageIsNotLoading());
        }
    },

    /**
     * Before entering reset password view
     * @param to
     * @param from
     * @param next
     * @constructor
     */
    BeforeEnterResetPassword(to, from, next) {
        next(() => this.data.app.$global.pageIsNotLoading());
    },

    /**
     * Before entering upcoming assessments view
     * @param to
     * @param from
     * @param next
     * @constructor
     */
    BeforeEnterUpcomingAssessments(to, from, next) {
        this.data.app.$global.pageIsLoading();
        if (this.data.app.$store.state.user === null) {
            this.redirectToLogin(to, from, next);
        } else {
            this.data.app.$axios.get(endpoint('/api/candidates/upcoming-assessments'))
                .then(response => {
                    this.data.app.$store.commit('setUpcomingAssessments', response.data);
                    next(() => this.data.app.$global.pageIsNotLoading());
                })
                .catch(() => {
                    this.data.app.$global.addErrorNotification({ message: this.data.app.$store.state.translatables.translation.notifications.messages.error_retrieving_assessment });
                    this.redirect(to, from, next);
                });
        }
    },

    /**
     * Before entering the waiting room view.
     * @param to
     * @param from
     * @param next
     * @constructor
     */
    BeforeEnterWaitingRoom(to, from, next) {
        this.data.app.$global.pageIsLoading();
        if (this.data.app.$store.state.user === null) {
            this.redirectToLogin(to, from, next);
        } else {
            next(() => this.data.app.$global.pageIsNotLoading());
        }
    },

    /**
     * Redirect to said path as long as the path is not the same as the current path.
     * (Vue Router doesn't like it if it is)
     * @param to
     * @param from
     * @param next
     * @param toPath
     */
    redirect(to, from, next, toPath) {
        toPath = toPath || (this.data.isSandbox ? '/sandbox' : '/');
        if (toPath !== to.path && toPath !== from.path) {
            next(toPath);
        } else {
            this.data.app.$global.pageIsNotLoading();
        }
    },

    redirectToLogin(to, from, next) {
        this.redirect(to, from, next, this.data.isSandbox ? '/sandbox/login' : '/login');
    }

}
