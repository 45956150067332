<template>
    <div>
        <layout :title="translation.headings.completed_assessments">
            <template v-slot:contents>
                <div v-if="assessments.length === 0">
                    <div class="rounded-md bg-yellow-50 p-4">
                        <div :class="rtl('flex-row-reverse')" class="flex">
                            <div class="flex-shrink-0">
                                <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                </svg>
                            </div>
                            <div :class="rtl('mr-3 text-right') + ltr('ml-3')">
                                <h3 class="text-sm leading-5 font-medium text-yellow-800">
                                    {{ translation.headings.no_completed_assessments }}
                                </h3>
                                <div class="mt-2 text-sm leading-5 text-yellow-700">
                                    <p>
                                        {{ translation.messages.no_completed_assessments }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ul v-else class="grid gap-6 grid-cols-2" test-ref="schedule-list">
                    <li v-for="assessment in assessments" test-ref="schedule" class="col-span-2 bg-white rounded-lg shadow-container">
                        <div class="bg-white px-4 pt-5 sm:px-6 rounded-t-md">
                            <h3 :class="rtl('text-right')" class="text-lg leading-6 font-medium text-gray-900 border-b border-gray-500 pb-4">
                                {{ assessment.version.assessment.title }}
                                <span class="block font-normal text-sm">Group: <span class="text-gray-500">{{ assessment.group.name }}</span></span>
                            </h3>
                        </div>
                        <div class="w-full p-6">
                            <div class="flex items-center justify-between space-x-6">
                                <div class="flex-1 truncate">
                                    <div class="flex items-center space-x-3">
                                        <h3 class="text-gray-900 text-sm leading-5 font-medium truncate flex">
                                            <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                            </svg>
                                            <span>{{ startsAt(assessment) }}</span>
                                        </h3>
                                        <span v-if="assessment.group.assessment_schedule.is_practise" class="flex-shrink-0 inline-block px-2 py-0.5 text-yellow-800 text-xs leading-4 font-medium bg-yellow-100 rounded-full">
                                            {{ translation.labels.practice_assessment }}
                                        </span>
                                    </div>
                                    <p class="mt-1 text-gray-500 text-sm leading-5 truncate">{{ assessment.group.assessment_schedule.name }} | {{ assessment.version.assessment.title }}</p>
                                </div>
                                <div class="flex items-center  text-green-800 rounded-full px-2 py-1 bg-green-100">
                                    <svg class="h-5 w-h mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span class="text-sm">{{ duration(assessment) }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="assessment.group.assessment_schedule.is_reviewable">
                            <div class="-mt-px flex">
                                <div class="w-0 flex-1 flex">
                                    <a :class="rtl('flex-row-reverse')" @click.prevent="sitAssessment(assessment)" href="#" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 leading-5 text-white bg-blue-700 font-medium border border-transparent rounded-b-lg hover:bg-blue-800 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150 focus:ring-2 focus:ring-blue-300 focus:bg-blue-600">
                                        <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                                        </svg>
                                        <span :class="rtl('mr-3') + ltr('ml-3')">{{ translation.buttons_links.review_assessment }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </template>
        </layout>
    </div>
</template>

<script>
    import Layout from "@/components/Layout.vue";
    import Loader from "@/components/Loader.vue";
    import ComputedBus from '@/utils/computed-bus.js';
    import MethodsBus from '@/utils/methods-bus.js';

    export default {
        components: {
            Layout,
            Loader
        },
        beforeRouteEnter (to, from, next) {
            window.bus.$gates.BeforeEnterCompletedAssessments(to, from, next)
        },
        mounted() {
            this.$global.disconnectFromChannels();
        },
        computed: {
            ...ComputedBus,
            assessments() {
                return this.$store.state.assessments.completed;
            },
        },
        methods: {
            ...MethodsBus,
            sitAssessment(assessment) {
                this.$global.pageIsLoading();
                this.$store.commit('setAssessment', assessment);
                this.$router.push({
                    name: 'review-assessment'
                });
            },
        }
    }
</script>
