import { createApp } from 'vue';
import App from './App.vue';
import '@/styles/main.scss';
import pusher from 'pusher-js';
window.Pusher = pusher;
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import axios from './plugins/axios';
import echo from './plugins/echo';
import setup from './plugins/setup';
import debug from './plugins/debug';
import router from './router';
import store from './store';

Bugsnag.start({
    apiKey: import.meta.env.VITE_BUG_SNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
})

const app = createApp(App)
    .use(Bugsnag.getPlugin('vue'))
    .use(setup)
    .use(store)
    .use(router)
    .use(axios)
    .use(debug)
    .use(echo);

app.mount('#app');
