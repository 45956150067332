<template>
    <div>
        <layout>
            <template v-slot:contents>
                <div v-if="translation" class="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div class="sm:mx-auto sm:w-full sm:max-w-md">
                        <h1 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                            {{ translation.headings.sign_in_account }}
                        </h1>
                    </div>
                    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <form role="form" ref="form" :class="rtl('text-right')" class="lg:bg-white py-8 px-4 lg:shadow sm:rounded-lg sm:px-10">
                            <div v-if="errors.global" class="text-red-600 text-sm mb-6">{{ errors.global }}</div>
                            <div>
                                <label aria-hidden="true" for="user_id" class="block text-sm font-medium leading-5 text-gray-700">
                                    {{ translation.labels.user_id }}
                                </label>
                                <div v-if="errors.user_id" class="text-red-500 text-sm">{{ errors.user_id }}</div>
                                <div class="mt-1 rounded-md shadow-sm">
                                    <input test-ref="input-userid" aria-label="User identification number" aria-required="true" required autocomplete="username" :class="rtl('text-right')" :disabled="processing" v-model="user_id" id="user_id" type="text" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                                </div>
                            </div>
                            <div v-if="!isSandbox" class="mt-6">
                                <label aria-hidden="true" for="school_id" class="block text-sm font-medium leading-5 text-gray-700">
                                    {{ translation.labels.school_id }}
                                </label>
                                <div v-if="errors.school_id" class="text-red-500 text-sm">{{ errors.school_id }}</div>
                                <div class="mt-1 rounded-md shadow-sm">
                                    <input test-ref="input-schoolid" aria-label="School identification number" aria-required="true" required autocomplete="organization" :class="rtl('text-right')" :disabled="processing" v-model="school_id" id="school_id" type="text" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                                </div>
                            </div>
                            <div class="mt-6">
                                <label aria-hidden="true" for="password" class="block text-sm font-medium leading-5 text-gray-700">
                                    {{ translation.labels.password }}
                                </label>
                                <div v-if="errors.password" class="text-red-500 text-sm">{{ errors.password }}</div>
                                <div class="mt-1 rounded-md shadow-sm">
                                    <input test-ref="input-password" aria-label="Account password" aria-required="true" required autocomplete="current-password" :class="rtl('text-right')" :disabled="processing" v-model="password" id="password" type="password" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                                </div>
                            </div>
                            <div class="mt-6 flex items-center justify-between">
                                <div  v-if="registrationEnabled" class="flex items-center text-sm leading-5">
                                    <a role="link" test-ref="register-for-account" :class="{'pointer-events-none select-none': processing}" @click.prevent="register" href="#" class="underline font-medium text-indigo-800 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150 focus:ring-2 focus:ring-indigo-800 focus:no-underline rounded">
                                        {{ translation.buttons_links.register_for_account }}
                                    </a>
                                </div>
                                <div class="text-sm leading-5" v-if="isSandbox">
                                    <a role="link" test-ref="forgotten-password" :class="{'pointer-events-none select-none': processing}" @click.prevent="forgotten" href="#" class="underline font-medium text-indigo-800 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150 focus:ring-2 focus:ring-indigo-800 focus:no-underline rounded">
                                        {{ translation.buttons_links.forgotten_password }}
                                    </a>
                                </div>
                            </div>
                            <div class="mt-6">
                                <span class="block w-full rounded-md shadow-sm">
                                    <button role="button" test-ref="sign-in" :disabled="processing" @click.prevent="login" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out focus:ring-2 focus:ring-indigo-300">
                                        <loader v-if="processing" class="w-5 h-5"></loader>
                                        <span v-else>{{ translation.buttons_links.sign_in }}</span>
                                    </button>
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
        </layout>
    </div>
</template>

<script>
    import Layout from "@/components/Layout.vue";
    import Loader from "@/components/Loader.vue";
    import MethodsBus from "@/utils/methods-bus.js";
    import ComputedBus from "@/utils/computed-bus.js";

    export default {
        beforeRouteEnter (to, from, next) {
            window.bus.$gates.BeforeEnterLogin(to, from, next);
        },
        components: {
            Layout,
            Loader
        },
        data() {
            return {
                processing: false,
                user_id: '',
                school_id: window.location.pathname.includes("sandbox") ? 'sandbox' : '',
                password: '',
                errors: {
                    global: '',
                    user_id: '',
                    school_id: '',
                    password: '',
                },
                isSandbox: window.location.pathname.includes("sandbox"),
                registrationEnabled: import.meta.env.VITE_REGISTRATION_ENABLED
            };
        },
        mounted() {
            this.$global.disconnectFromChannels();
        },
        computed: {
            ...ComputedBus
        },
        methods: {
            ...MethodsBus,
            login() {
                this.clearErrors();
                if(! this.$refs.form.checkValidity()) {
                    return this.$refs.form.reportValidity();
                }
                this.processing = true;
                this.setXSRFToken(() => {
                     this.$axios.post(endpoint('/sanctum/candidates/token'), this.payload()).then((response) => {
                         this.$store.commit('setUser', response.data.user);
                         this.$store.commit('setToken', response.data.token);
                         this.$store.commit('setLoggedInAtTime', new Date().toUTCString());
                         this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.token;
                         this.$registerWSSConnection(() => {
                             this.$registerWSSBindings(this);
                             this.processing = false;
                             this.$router.push({
                                 name: 'home'
                             });
                         }, () => {
                             this.processing = false;
                             //this.$store.commit('clear', this);
                             this.$global.error('Could not connect to websocket server.');
                         });
                     })
                     .catch(error => {
                         this.$global.globalAXIOSErrorHandler(error, false, this);
                         this.processing = false;
                    });
                });
            },
            payload() {
                return {
                    locale: this.$store.state.translatables.locale,
                    user_id: this.user_id,
                    school_id: this.school_id,
                    password: this.password,
                    is_sandbox: this.isSandbox,
                    device_name: 'chrome'
                }
            },
            clearErrors() {
                this.errors = {
                    global: '',
                    user_id: '',
                    school_id: '',
                    password: '',
                }
            },

        }
    }
</script>
