<template>
    <div v-if="user" ref="profile_menu" :class="rtl('mr-3') + ltr('ml-3')" class="relative print:hidden">
        <div class="print:hidden">
            <button @click.prevent="open = !open" class="menu-button p-1 border-2 border-transparent text-gray-300 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300" id="user-menu" aria-label="User menu" aria-haspopup="true">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </button>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <div v-show="open" :class="rtl('origin-top-left left-0 text-right') + ltr('origin-top-right right-0')" class="absolute mt-2 w-48 rounded-md shadow-lg">
                <div role="menu" class="py-1 rounded-md bg-white shadow-xs">
                    <a aria-label="Show local saves modal" role="menuitem" id="local-saves-button" @click.prevent="show_blobs_modal = !show_blobs_modal" href="#" class="logout-button block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">{{ translation.buttons_links.local_saves }}</a>
                    <a aria-label="Log out of this application" role="menuitem" id="logout-button" @click.prevent="logout" href="#" class="logout-button block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">{{ translation.buttons_links.sign_out }}</a>
                </div>
            </div>
        </transition>
    </div>
    <blobs-modal :is-open="show_blobs_modal" @close="show_blobs_modal = false"></blobs-modal>
</template>

<script>
    import MethodsBus from "@/utils/methods-bus.js";
    import BlobsModal from "@/components/BlobsModal.vue";

    export default {
        emits: ['logout', 'setState'],
        components: {
            BlobsModal
        },
        data() {
            return {
                show_blobs_modal: false
            }
        },
        props: {
            open: {
                default: false
            }
        },
        mounted() {
            document.addEventListener('click', this.binding);
        },
        beforeUnmount() {
            window.removeEventListener('beforeunload', this.binding);
        },
        computed: {
            showLocalBlobs() {
                return this.$store.state.player_config.show_local_blobs;
            },
            translation() {
                return this.$store.state.translatables.translation;
            },
            user() {
                return this.$store.state.user;
            }
        },
        methods: {
            ...MethodsBus,
            binding(evt) {
                evt.stopPropagation();
                if(this.$refs.profile_menu) {
                    if(!this.$refs.profile_menu.contains(evt.target)) {
                        this.$emit('setState', false);
                    }
                }
            },
            logout() {
                this.$emit('logout', true);
            }
        }
    }
</script>
