<template>
    <div style="z-index: 99999999999" v-show="loading" class="fixed z-100 left-0 bottom-0 top-0 right-0 px-4 pb-6 sm:p-0 flex items-center justify-center">
        <transition
            enter-active-class="ease-out duration-300"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-200 ease-in"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div style="z-index: 99999999999" v-show="loading" class="fixed inset-0 transition-opacity">
                <div class="fixed inset-0 bg-gray-800 opacity-75 flex items-center justify-center">
                </div>
                <div class="fixed left-0 bottom-0 top-0 right-0 flex items-center justify-center">
                    <loader class="w-8 text-white"></loader>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import Loader from "@/components/Loader.vue";

    export default {
        components: {
            Loader
        },
        computed: {
            loading() {
                return this.$store.state.page_loading;
            }
        }
    }
</script>
