
export default {

    /**
     * Sit Assessment
     * @param assessment
     */
    sitAssessment(assessment) {
        this.$global.pageIsLoading();
        if(assessment.group.assessment_schedule.proctored) {
            this.$axios.post(endpoint(`/api/candidates/proctoring/${assessment.group.assessment_schedule.proctor_service}/${assessment.id}`))
                .then(response => {
                    window.location = response.data.session_url;
                })
                .catch(() => {
                    this.$global.pageIsNotLoading();
                });
        } else {
            this.$store.commit('setAssessment', assessment);
            this.$router.push({
                name: 'assessment',
                params: {
                    id: assessment.id
                }
            });
        }
    },

    /**
     * Enter Waiting Room
     * @param assessment
     */
    enterWaitingRoom(assessment) {
        this.$store.commit('setAssessment', assessment);
        this.$router.push({
            name: 'waiting-room'
        });
    },

    /**
     * Duration
     * @param assessment
     */
    duration(assessment) {
        let minutes = assessment.group.assessment_schedule.max_time + assessment.extra_time;
        let hours = Math.floor(minutes / 60);
        minutes = minutes - (hours * 60);

        return this.trans(`${hours} hours ${minutes} minutes`, this.$store.state.translatables.translation.datetime);
    },

    /**
     * Starts At
     * @param assessment
     */
    startsAt(assessment) {
        return new Date(assessment.group.assessment_schedule.window_starts_at).toLocaleString(this.$store.state.translatables.to_locale_string, {
            weekday: 'short',
            day: 'numeric',
            year: 'numeric',
            month: 'long',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    },

    /**
     * Ends At Count Down
     * @param assessment
     */
    endsAtCountDown(assessment) {
        return this.$global.fromNowFormatted(assessment.group.assessment_schedule.window_ends_at);
    },

    /**
     * Starts At Count Down
     * @param assessment
     */
    startsAtCountDown(assessment) {
        return this.$global.fromNowFormatted(assessment.group.assessment_schedule.window_starts_at);
    },

    /**
     * Has Lapsed
     * @param assessment
     */
    hasLapsed(assessment) {
        return (function() {
            return new Date(assessment.group.assessment_schedule.window_starts_at) - new Date() <= 0
        })(this.seconds_lapsed * 0);
    },

    /**
     * Update the current assessment objects session times received from the server.
     * @param sessionId
     * @param session
     * @param assessment
     */
    updateSessionTimes(sessionId, session, assessment) {
        assessment.ends_at = session.ends_at;
        assessment.timer_ends_at = session.timer_ends_at;
        assessment.candidate_started_at = session.candidate_started_at;
        assessment.started_at = session.started_at;
        assessment.submitted_at = session.submitted_at;
        assessment.max_time = session.max_time;
    },

    /**
     * Trans
     * @param message
     * @param replace
     * @returns {*}
     */
    trans(message, replace) {
        replace = replace || {};
        for(let i in replace) {
            if(replace.hasOwnProperty(i)) {
                message = message.replace(i, replace[i]);
            }
        }
        return message;
    },

    /**
     *
     * @param classes
     * @returns {*}
     */
    rtl(classes) {
        return this.$store.state.translatables.direction === 'rtl' ? classes : '';
    },

    /**
     *
     * @param classes
     * @returns {*}
     */
    ltr(classes) {
        return this.$store.state.translatables.direction === 'ltr' ? classes : '';
    },

    /**
     * Get a fresh CSRF token
     * @param callback
     */
    setXSRFToken(callback) {
        this.$global.setXSRFToken(callback);
    },

    /**
     * Login Route
     */
    login() {
        this.$router.push({
            name: 'login'
        });
    },

    /**
     * Register Route
     */
    register() {
        this.$router.push({
            name: 'register'
        });
    },

    /**
     * Forgotten Password Route
     */
    forgotten() {
        this.$router.push({
            name: 'forgotten-password'
        });
    },


}
