<template>
    <popup v-if="translation" role="contentinfo" :open="open">
        <template v-slot:content>
            <p class="mb-3">{{ translation.messages.allow_seb_run }}</p>

            <p>{{ translation.messages.install_seb }}</p>

            <div class="mt-2">
                <a @click.prevent="close" class="underline cursor-pointer">
                    {{ translation.buttons_links.close }}
                </a>
            </div>
        </template>
    </popup>
</template>

<script>
    import Popup from "./Popup.vue";
    import ComputedBus from "../utils/computed-bus.js";

    export default {
        emits: ['close'],
        props: {
            open: { type: Boolean, default: false },
        },
        components: {
            Popup,
        },
        computed: ComputedBus,
        methods: {
            close() {
                this.$emit('close');
            },
        }
    }
</script>
