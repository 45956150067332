
<template>
    <assessment :proctored="false"></assessment>
</template>

<script>

import Assessment from "@/components/Assessment.vue";

export default {
    beforeRouteEnter (to, from, next) {
        window.bus.$gates.BeforeEnterAssessment(to, from, next);
    },
    components: {
        Assessment
    }
}
</script>
