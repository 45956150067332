
import global from "../utils/global";
import gates from "../utils/gates";
import saves from './local-saves';

export default {
    install: (app) => {
        app.config.globalProperties.$global = global;
        app.config.globalProperties.$saves = saves;

        window.bus = {
            '$gates': app.config.globalProperties.$gates = gates
        }
    }
}
