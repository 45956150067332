<template>
    <popup :open="isOpen" :title="'Saved Assessment Blob'">
        <template v-slot:content>
            <div class="text-left">
                <h2 class="mt-6 block text-sm font-medium leading-5 text-gray-700">{{ blobTitle }}</h2>
                <div>
                    <select v-model="blob" class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option :value="sessionId" v-for="(blob, sessionId) in blobs">{{ sessionId }}</option>
                    </select>
                </div>
                <div class="mt-4">
                    <label for="data" class="block text-sm font-medium leading-5 text-gray-700">
                        Data
                    </label>
                    <div class="mt-1 rounded-md shadow-sm">
                        <textarea ref="blob" id="data" class="h-64 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md">{{ blobData }}</textarea>
                    </div>
                    <p class="mt-2 text-sm text-gray-500">If your internet connection has dropped, please either copy or download your session data and send it over to us with your name and email address attached.</p>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="sm:flex sm:flex-row-reverse mt-6">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button @click.prevent="close" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                        Cancel
                    </button>
                </span>
                <span class="mt-3 flex w-full sm:ml-3 rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button @click.prevent="download" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                        Download
                    </button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button @click.prevent="copy" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                        Copy
                    </button>
                </span>
            </div>
        </template>
    </popup>
</template>

<script>
import Popup from "@/components/Popup.vue";
import FileDownload from "js-file-download";

export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            blob: ''
        }
    },
    components: {
        Popup
    },
    computed: {
        blobs() {
            let blobs = {};
            if(this.$store.state.user) {
                for(let i in this.$store.state.assessment_blob) {
                    if(parseInt(this.$store.state.assessment_blob[i].user_id) === parseInt(this.$store.state.user.id)) {
                        blobs[i] = this.$store.state.assessment_blob[i];
                    }
                }
            }
            return blobs;
        },
        blobData() {
            if(! this.blob) return '';
            return this.blobs[this.blob].blob;
        },
        blobTitle() {
            if(! this.blob) return '';
            return this.blobs[this.blob].title;
        }
    },
    methods: {
        close() {
            this.$emit('close', true);
        },
        copyTextArea() {
            this.$refs.blob.select();
            document.execCommand('copy');
        },
        copy(e) {
            this.copyTextArea();
            e.target.innerHTML = 'Copied!';
            setTimeout(() => {
                e.target.innerHTML = 'Copy';
            }, 1000);
        },
        download() {
            if(! this.blob) return '';
            FileDownload(this.blobs[this.blob].blob, this.blob + '.txt');
        }
    }
}
</script>
