<template>
    <assessment :proctored="true"></assessment>
</template>

<script>
    import Assessment from "@/components/Assessment.vue";

    export default {
        beforeRouteEnter (to, from, next) {
            window.bus.$gates.BeforeEnterProctoredAssessment(to, from, next);
        },
        components: {
            Assessment
        }
    }
</script>
