"use strict";

import axios from "axios";

axios.defaults.headers.common['Access-Control-Allow-Origin'] = "'self' *.pearson.com *.dev-openclass.com *.pearson-intl.com";
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;

const _axios = axios.create();

window.endpoint = function(endpoint) {
    return import.meta.env.VITE_API_URL + endpoint;
};

window.websocketEndpoint = function(endpoint) {
    return import.meta.env.VITE_WEBSOCKET_URL + endpoint;
};

_axios.interceptors.request.use(
    function(config) {
        // Do something before request is sent
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {
        // Do something with response data
        return response;
    },
    function(error) {
        // Do something with response error
        return Promise.reject(error);
    }
);

export default {
    install: (app) => {
        app.config.globalProperties.$axios = _axios;
        window.bus.$gates.registerBootFunc((inst) => {
            inst.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + inst.$store.state.token;
        });
    }
}
