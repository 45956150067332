<template>
    <router-view/>
    <page-loader></page-loader>
    <notifications></notifications>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
import Notifications from "@/components/Notifications.vue";

export default {
    components: {
        PageLoader,
        Notifications
    },
    beforeMount() {
        this.$debug.log('Application beforeMount()');
        this.$gates.app(this)
            .$global.app(this)
            .$saves.app(this)
            .$global.pageIsLoading();
        this.loadReadSpeaker();
    },
    mounted() {
        this.$debug.log('Application mounted()');
        this.$gates.booted(this);
        if(this.$store.state.user) {
            this.$gates.authBooted(this);
        }

        /**
         * Using requestAnimationFrame to get the timestamp
         */
        // window.requestAnimationFrame(this.loggedInCheck);
        this.$global.startInterval();
        this.$global.translatables(this.$store.state.translatables ? this.$store.state.translatables.locale : '');
    },
    data() {
        return {
            last_check: null
        }
    },
    methods: {
        /**
         * Check every minute to make sure the user is still logged in.
         * We are using token based auth so the user is more than likely to be logged until the tokens are revoked.
         * Mainly using this to check connection to the server.
         * @param timestamp
         */
        loggedInCheck(timestamp) {
            timestamp = Math.floor(Number(timestamp.toFixed(0))/1000);
            if(timestamp % 60 === 0 && this.last_check !== timestamp) {
                this.last_check = timestamp;
                if(['/login', '/register'].indexOf(this.$route.path) === -1) {
                    this.$global.getUser(this);
                }
            }
            window.requestAnimationFrame(this.loggedInCheck);
        },
        loadReadSpeaker() {
            let readSpeaker = document.createElement('script')
            readSpeaker.setAttribute('src', '//cdn-na.readspeaker.com/script/12984/webReaderForEducation/learnosity/current/ReadSpeaker.Learnosity.js')
            let tools = {};
            let disableTools = false;
            (import.meta.env.VITE_READSPEAKER_DISABLED_TOOLS || '').split(',').forEach(function(tool){
                if(tool === ''){
                    return;
                }
                tools[tool] = false;
                disableTools = true;
            });
            if(disableTools){
                window.rsConf = {
                    ui: {
                        tools: tools
                    }
                };
            }
            document.head.appendChild(readSpeaker)
        }
    }
}
</script>
