
export default {

    /**
     * Translation
     * @returns {*}
     */
    translation() {
        return this.$store.state.translatables.translation;
    },

}
