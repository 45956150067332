<template>
    <popup v-if="translation && user" role="contentinfo" :open="isOpen" :title="translation.headings.network_connection" test-ref="websocket-modal">
        <template v-slot:content>
            <p class="mb-4 text-black mt-4">{{ translation.labels.current_network_state }} <span :class="stateClass">{{ state }}</span></p>
            <div class="" v-if="rawState === 'connecting' || rawState === 'initialising'">
                <span class="block mb-2">{{ translation.headings.reconnecting }}</span>
                <div class="flex justify-center">
                    <loader class="w-8 my-6"></loader>
                </div>
            </div>
            <div v-else-if="rawState === 'unavailable' || rawState === 'offline'">
                <p>{{ translation.messages.no_connection }}</p>

                <div v-if="assessmentPage">
                    <div class="mt-5 sm:mt-6">
                        <button @click="proceed = true" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                            {{ translation.buttons_links.continue_offline }}
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </popup>
</template>

<script>
    import Popup from "./Popup.vue";
    import Loader from "./Loader.vue";
    import ComputedBus from "../utils/computed-bus.js";
    import MethodsBus from "../utils/methods-bus.js";

    export default {
        components: {
            'popup': Popup,
            'loader': Loader
        },
        data() {
            return {
                proceed: false
            }
        },
        computed: {
            ...ComputedBus,
            stateClass() {
                switch(this.rawState) {
                    case 'connecting' : return 'text-yellow-500';
                    case 'initialising' : return 'text-yellow-500';
                    case 'unavailable' : return 'text-red-500';
                    case 'offline' : return 'text-red-500';
                }
            },
            user() {
                return this.$store.state.user;
            },
            isOpen() {
                return ! this.proceed && this.rawState !== 'connected';
            },
            rawState() {
                return this.$store.state.state;
            },
            state() {
                return this.translation.labels.connection_states[this.$store.state.state];
            },
            isRightToLeft() {
                return this.$store.state.translatables.direction === 'rtl';
            },
            assessmentPage() {
                return ['assessment', 'proctored-assessment'].includes(this.$route.name);
            },
        },
        methods: {
            ...MethodsBus,
        }
    }
</script>
