<template>
    <div>
        <div class="bg-yankees-blue pb-32">
            <nav class="bg-yankees-blue">
                <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div class="border-b border-gray-700">
                        <div :class="rtl('flex-row-reverse')"
                             class="flex items-center justify-between h-16 px-4 sm:px-0 text-white">
                            <div :class="rtl('flex-row-reverse')" class="flex items-center">
                                <div :class="rtl('ml-4')" class="flex-shrink-0">
                                    <img alt="Pearson logo" aria-label="Pearson Logo" class="h-8 bg-gray-800"
                                         src="/images/logo.svg">
                                </div>
                                <div v-if="user && !iframe && ! assessmentPage" class="hidden md:block print:hidden">
                                    <div :class="rtl('flex-row-reverse mr-10 space-x-reverse') + ltr('ml-10')"
                                         class="flex space-x-4 items-baseline"
                                         role="navigation">
                                        <router-link id="assessments" aria-label="View active assessments"
                                                     class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                                                     exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                                                     role="link"
                                                     to="/">{{ translation.headings.assessments }}
                                        </router-link>
                                        <router-link v-if="!sandbox" id="upcoming-assessments"
                                                     aria-label="View upcoming assessments"
                                                     class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                                                     exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                                                     role="link"
                                                     to="/upcoming-assessments">{{ translation.headings.upcoming }}
                                        </router-link>
                                        <router-link v-if="!sandbox" id="completed-assessments"
                                                     aria-label="View completed assessments"
                                                     class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                                                     exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                                                     role="link"
                                                     to="/completed-assessments">{{ translation.headings.completed }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="print:hidden">
                                <div :class="rtl('flex-row-reverse mr-4 md:mr-6') + ltr('ml-4 md:ml-6')"
                                     class="items-center hidden md:flex">
                                    <div v-if="user" aria-label="Your full name" class="text-white mr-4 text-sm">
                                        {{ user.full_name }}
                                    </div>
                                    <language-switcher></language-switcher>
                                    <profile-menu v-if="!iframe" :open="profile_open" @logout="logout"
                                                  @setState="setProfileMenuState"></profile-menu>
                                </div>
                            </div>
                            <div v-if="user" class="-mr-2 flex md:hidden print:hidden">
                                <button
                                    class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white focus:ring-2 focus:ring-indigo-300"
                                    @click.prevent="mobile_menu_open = !mobile_menu_open">
                                    <svg v-if="! mobile_menu_open" aria-hidden="true" class="block h-6 w-6"
                                         fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path d="M4 6h16M4 12h16M4 18h16" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2"/>
                                    </svg>
                                    <svg v-if="mobile_menu_open" aria-hidden="true" class="h-6 w-6"
                                         fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2"/>
                                    </svg>
                                </button>
                                <language-switcher :class="rtl('ml-3') + ltr('mr-3')"></language-switcher>
                            </div>
                        </div>
                    </div>
                </div>
                <mobile-menu :iframe="iframe" :open="mobile_menu_open" @logout="logout"></mobile-menu>
            </nav>
            <header aria-label="Heading" class="py-10 print:hidden" role="group">
                <slot name="title"></slot>
                <div v-if="! hasTitleOverride()" class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h1 v-if="title" :class="rtl('text-right')" class="text-3xl leading-9 font-bold text-white">
                        {{ title }}
                    </h1>
                </div>
            </header>
        </div>
        <main class="-mt-32" role="main">
            <div class="max-w-5xl mx-auto pb-12 px-4 sm:px-6 lg:px-8 print:px-0">
                <div
                    class="bg-white rounded-lg border-l border-r border-gray-50 px-2 py-2 sm:py-6 sm:px-6 min-h-body print:rounded-none">
                    <slot name="contents"></slot>
                </div>
                <div></div>
            </div>
        </main>

        <popup :open="isModalOpen" :title="modalTitle" width="4xl">
            <template v-slot:content class="w-full">

                <cookie-policy v-if="modalName==='cookie_policy'"/>
                <legal-notice v-else-if="modalName==='legal_notice'"/>
                <privacy-notices v-else-if="modalName==='privacy_policy'"/>
                <accessibility-statement v-else-if="modalName==='accessibility_statement'"/>

                <button class="bg-red-700 text-white px-4 py-2 rounded-lg mt-10 font-bold" @click="closeModal">Close
                </button>
            </template>
        </popup>

        <footer v-if="translation"
                class="bg-white border-t border-gray-100 max-w-7xl mx-auto sm:px-6 lg:px-8 print:hidden">
            <div class="max-w-screen-xl mx-auto py-6 px-4 overflow-hidden space-y-8 sm:px-6 lg:px-8">
                <nav v-if="! assessmentPage" class="-mx-5 -my-2 flex flex-wrap justify-center">
                    <div class="px-5 py-2 cursor-pointer" @click="openModal('privacy_policy')">
                        <span class="text-sm leading-6 text-gray-500">
                            {{ translation.buttons_links.privacy_policy }}
                        </span>
                    </div>
                    <div class="px-5 py-2 cursor-pointer" @click="openModal('cookie_policy')">
                        <span class="text-sm leading-6 text-gray-500">
                            {{ translation.buttons_links.cookie_policy }}
                        </span>
                    </div>
                    <div class="px-5 py-2 cursor-pointer" @click="openModal('legal_notice')">
                        <span class="text-sm leading-6 text-gray-500">
                            {{ translation.buttons_links.legal_notice }}
                        </span>
                    </div>
                    <div class="px-5 py-2 cursor-pointer" @click="openModal('accessibility_statement')">
                        <span class="text-sm leading-6 text-gray-500">
                            {{ translation.buttons_links.accessibility_statement }}
                        </span>
                    </div>
                </nav>
                <p class="mt-8 text-center text-xs leading-6 text-gray-500">© {{ date }} Pearson · {{ version }}</p>
            </div>
        </footer>
        <network-connection></network-connection>
    </div>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import NetworkConnection from "@/components/NetworkConnection.vue";
import MobileMenu from "@/components/MobileMenu.vue";
import ProfileMenu from "@/components/ProfileMenu.vue";
import MethodsBus from "@/utils/methods-bus.js";
import Popup from "@/components/Popup.vue";
import CookiePolicy from "@/components/CookiePolicy.vue";
import LegalNotice from "@/components/LegalNotice.vue";
import PrivacyNotices from "@/components/PrivacyNotices.vue";
import AccessibilityStatement from "@/components/AccessibilityStatement.vue";

export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        iframe: {
            type: Boolean,
            default: false
        }
    },
    components: {
        PrivacyNotices,
        LegalNotice,
        CookiePolicy,
        AccessibilityStatement,
        Popup,
        NetworkConnection,
        LanguageSwitcher,
        MobileMenu,
        ProfileMenu,
    },
    data() {
        return {
            profile_open: false,
            mobile_menu_open: false,
            isModalOpen: false,
            modalTitle: "Modal Title",
            modalName: null
        };
    },
    computed: {
        assessmentPage() {
            return this.$route.name === 'assessment';
        },
        date() {
            return new Date().getFullYear();
        },
        version() {
            return this.$store.state.translatables.version;
        },
        translation() {
            return this.$store.state.translatables.translation;
        },
        user() {
            return this.$store.state.user;
        },
        sandbox() {
            return window.location.pathname.includes("sandbox");
        },
    },
    methods: {
        ...MethodsBus,
        setProfileMenuState(state) {
            this.profile_open = state;
        },
        hasTitleOverride() {
            return !!this.$slots.title;
        },
        logout() {
            this.$global.pageIsLoading();
            this.profile_open = false;
            this.setXSRFToken(() => {
                this.$axios.post(endpoint('/sanctum/candidates/revoke'), {}).then(() => {
                    this.$global.disconnectFromChannels(true);
                    this.$store.commit('clear', this);
                    this.$router.push({
                        name: 'login'
                    });
                })
                    .catch(() => {
                        this.$store.commit('clear', this);
                        this.$router.push({
                            name: 'login'
                        });
                    });
            });
        },
        setXSRFToken(callback) {
            this.$axios.get(endpoint('/sanctum/csrf-cookie'))
                .then(callback);
        },
        route(route) {
            this.$router.push(route);
        },
        closeModal() {
            this.isModalOpen = false
        },
        toggleModal() {
            this.isModalOpen = !this.isModalOpen;
        },
        openModal(name) {
            this.isModalOpen = true;
            this.modalName = name;

            switch (name) {
                case "privacy_policy":
                    this.modalTitle = "Privacy Policy"
                    break;
                case "cookie_policy":
                    this.modalTitle = "Cookie Policy"
                    break;
                case "legal_notices":
                    this.modalTitle = "Legal Notice"
                    break;
                case "accessibility_statement":
                    this.modalTitle = "Accessibility Statement"
                    break;

            }
        }
    }
}
</script>
