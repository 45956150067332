export default {

    data: {
        app: null,
    },

    app(app) {
        this.data.app = app;

        return app;
    },

    checkLocalSaves() {
        if(! this.data.app.$store.state.user) {
            return null;
        }

        let shouldBlockLearnosity = ['assessment', 'proctored-assessment'].includes(this.data.app.$route.name);
        this.data.app.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.data.app.$store.state.token;
        this.data.app.$debug.log('Checking local saves...');
        this.data.app.$store.commit('setShouldLoadLearnosity', true);
        for(let i in this.data.app.$store.state.assessment_blob) {
            if(this.data.app.$store.state.assessment_blob.hasOwnProperty(i)) {
                let save = this.data.app.$store.state.assessment_blob[i];
                if(typeof save.blob !== 'undefined' && ! save.is_practice && ! save.dispatched) {
                    if(parseInt(save.user_id) === parseInt(this.data.app.$store.state.user.id)) {
                        this.data.app.$global.pageIsLoading();
                        this.data.app.$debug.log('Local save detected! Sending to Learnosity now...');
                        this.data.app.$axios.post(endpoint(`/api/candidates/schedule-group/${save.group_id}/events/save-blob-and-update-learnosity`), {
                            session_id: save.session_id,
                            blob: save.blob
                        }).then(() => {
                            this.data.app.$global.pageIsNotLoading();
                        }).catch(error => {
                            this.data.app.$global.pageIsNotLoading();
                            console.log(error);
                        });
                        // This will always run before the loading of Learnosity. If we have a local save
                        // which has not yet been dispatched we don't want to load the Learnosity UI because
                        // it will override. Worst case scenario is it will be stale by 10 seconds.
                        save.dispatched = true;
                        if(shouldBlockLearnosity) {
                            this.data.app.$store.commit('setShouldLoadLearnosity', false);
                        }
                    }
                } else if(typeof save.blob === 'undefined') {
                    // Old deprecated way of storing
                    delete this.data.app.$store.state.assessment_blob[i];
                } else if(new Date(save.save_time) < new Date().setDate(new Date().getDate() - 30)) {
                    // If local save is more than 30 days old delete the data from storage.
                    delete this.data.app.$store.state.assessment_blob[i];
                }
            }
        }
    }

}
