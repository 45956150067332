
export default {

    listenToSocketChannels() {
        this.$global.registerWhisperListener('assessment:end', 'public-group', message => {
            this.$debug.log('Assessment ended:', message);
            if(message.session_id) {
                if(message.session_id === this.session.id) {
                    this.save();
                    this.submitAssessment();
                }
            } else {
                this.save();
                this.submitAssessment();
            }
        });

        this.$global.registerWhisperListener('invigilator:clear-help', 'public-group', message => {
            if(message.session_id === this.session.id) {
                this.$debug.log('Invigilator cleared help request:', message);
                this.help = false;
            }
        });

        this.$global.registerWhisperListener('delegate:invigilator:pause', 'public-group', message => {
            if(message.session_id === this.session.id) {
                this.$debug.log('Invigilator paused individual session:', message);
                this.$store.state.assessment.paused = true;
                this.pausedRecently = true;
            }
        });

        this.$global.registerWhisperListener('delegate:invigilator:resume', 'public-group', message => {
            if(message.session_id === this.session.id) {
                this.$debug.log('Invigilator resumed individual session:', message);
                this.$store.state.assessment.paused = false;
            }
        });

        this.$global.registerWhisperListener('delegate:invigilator:pause_all', 'public-group', message => {
            this.$debug.log('Invigilator paused all sessions:', message);
            this.$store.state.assessment.paused = true;
            this.pausedRecently = true;
        });

        this.$global.registerWhisperListener('delegate:invigilator:resume_all', 'public-group', message => {
            this.$debug.log('Invigilator resumed all sessions:', message);
            this.$store.state.assessment.paused = false;
        });

        this.$global.registerListener('.candidate:time-sync', 'private', message => {
            if(message.session.id === this.session.id) {
                this.$debug.log('Message received from server of time sync:', message);
                this.updateSessionTimes(this.session.id, message.session, this.session);
                this.$nextTick(() => {
                    this.pausedRecently = false;
                });
            }
        });

        this.$global.registerListener('.candidate:learnosity-synced', 'private', message => {
            if(message.session.id === this.session.id) {
                this.$debug.log('Message received from server of time sync:', message);
                setTimeout(() => {
                    location.reload();
                }, 5000);
            }
        });

        this.$global.registerWhisperListener('invigilator:get-blobs', 'public-group', message => {
            if(message.session_id === this.session.id) {
                this.$debug.log('Message received from server of blobs request:', message);
                this.inform('candidate:receive-blobs', {
                    invigilator_id: message.invigilator_id,
                    blob: this.$store.state.assessment_blob[this.session.id]
                });
                this.$debug.log('Message sent back to invigilator');
            }
        });

        this.$global.registerListener('.invigilator:assessment-start', 'public-group', message => {
            this.$debug.log('Invigilator started assessment:', message);
            this.session.group.started_by_invigilator_at = message.group.started_by_invigilator_at;
            this.status = 'active';
            this.$nextTick(() => {
                this.loadLearnosity();
            });
        });

    }

}
