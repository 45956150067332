
let DEBUG = false;

export default {

    install: (app) => {

        app.config.globalProperties.$debug = {

            /**
             * Log
             * @param message
             * @param data
             */
            log(message, data) {
                data = data || false;
                if(DEBUG) {
                    console.log(message);
                    if(data) {
                        console.log(data);
                    }
                }
            },

            /**
             * Enable
             */
            enable() {
                DEBUG = true;
            }

        };

    }

}
